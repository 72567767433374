<script setup lang="ts">
import IconsPlusButton from '@/components/icons/IconsPlusButton.vue'
import { useAtlantisLink } from '@/composables/useAtlantisLink'
import { usePageStore } from '@voix/store/pageStore'

const props = defineProps({
  fields: {
    type: Object,
    default: () => ({}),
  },
  slice: {
    type: Object,
    default: () => ({}),
  },
})

defineSlice({
  name: { label: 'Dining Feature', group: 'Dining', layouts: ['*'] },
  preview: 'SlicesGlueDiningDiningFeature.jpg',
  description: 'Dining Feature Card',
  fields: {
    image: {
      label: 'Image',
      type: 'media',
      breakpoints: {
        default: {
          width: 600,
          height: 400,
        },
        md: {
          width: 1200,
          height: 800,
        },
      },
      group: 'Image',
    },
    size: {
      type: 'select',
      label: 'Size',
      allowNull: false,
      options: {
        small: 'Small',
        medium: 'Medium',
        large: 'Large',
      },
      default: 'small',
    },
    imageRight: { type: 'checkbox', label: 'Image Right?', group: 'Image' },
    showBlueCard: { type: 'checkbox', label: 'Show Blue Card at Top?', group: 'Blue Card' },
    blueCardTop: { label: 'Blue Card Top', type: 'text', group: 'Blue Card' },
    blueCardBottom: { label: 'Blue Card Bottom', type: 'text', group: 'Blue Card' },
    subtitle: { label: 'Subtitle', type: 'text', group: 'Title' },
    title: { label: 'Title', type: 'text', group: 'Title' },
    titleUnderline: { label: 'Title has underline?', type: 'checkbox', default: true, group: 'Title' },
    textAlignment: { label: 'Text Alignment', type: 'select', options: { left: 'Left', center: 'Center', right: 'Right' }, group: 'Title' },
    copy: { label: 'Copy', type: 'wysiwyg' },
    link: { label: 'Button Link', type: 'link', enabled: true, group: 'Links' },
    linkMode: { type: 'select', label: 'Link Mode', allowNull: false, options: { linedraw: 'Line Draw', ghost: 'Ghost' }, default: 'ghost', group: 'Links' },
    link2: { label: 'Button Link 2', type: 'link', enabled: true, group: 'Links' },
    linkMode2: { type: 'select', label: 'Link Mode 2', allowNull: false, options: { linedraw: 'Line Draw', ghost: 'Ghost' }, default: 'ghost', group: 'Links' },
    showBookNow: { type: 'checkbox', label: 'Show Book Now Button?', group: 'Booking Options' },
    overrideBookNowLabel: { type: 'text', label: 'Override Book Now Label', enabled: false, group: 'Booking Options' },
    cobrand: { type: 'text', label: 'Cobrand', group: 'Booking Options' },
    secondaryLink: { label: 'Very Bottom Link', type: 'link', group: 'Links' },
    secondaryLinkMode: { type: 'select', label: 'Very Bottom Link Mode', allowNull: false, options: { linedraw: 'Line Draw', ghost: 'Ghost' }, default: 'ghost', group: 'Links' },
    largeMargin: { type: 'checkbox', label: 'Large Margin?', default: true },
    galleryImage1: { type: 'media', label: 'Gallery Image 1', breakpoints: { lg: { width: 1200, height: 675 }, sm: { width: 350, height: 350 } }, group: 'Gallery' },
    galleryImage2: { type: 'media', label: 'Gallery Image 2', breakpoints: { lg: { width: 1200, height: 675 }, sm: { width: 350, height: 350 } }, group: 'Gallery' },
    galleryImage3: { type: 'media', label: 'Gallery Image 3', breakpoints: { lg: { width: 1200, height: 675 }, sm: { width: 350, height: 350 } }, group: 'Gallery' },
    galleryImage4: { type: 'media', label: 'Gallery Image 4', breakpoints: { lg: { width: 1200, height: 675 }, sm: { width: 350, height: 350 } }, group: 'Gallery' },
    galleryImage5: { type: 'media', label: 'Gallery Image 5', breakpoints: { lg: { width: 1200, height: 675 }, sm: { width: 350, height: 350 } }, group: 'Gallery' },
  },
  templates: [{
    label: 'Dining Feature',
    fields: {
      copy: { value: '<p>Throughout the year, there are countless ways to celebrate holidays and special occasions at Atlantis.<br><br>Leave the planning to us while you enjoy the unique events, festive celebrations, and countless ways to indulge in the many gifts of The Bahamas.<\/p>' },
      image: { value: 'https:\/\/atlantis-cms-assets.s3.us-east-2.amazonaws.com\/media\/2022-content\/experiences\/aquaventure\/atlantis-baths-collonade-pool-family-splashing.jpeg' },
      imageRight: { value: true },
      link: { value: { text: 'join the fun', target: '_self', relationship: null, href: '\/new-page' } },
      linkMode: { value: 'ghost' },
      linkMode2: { value: 'linedraw' },
      size: { value: 'large' },
      subtitle: { value: 'at Atlantis' },
      textAlignment: { value: 'center' },
      title: { value: 'spring into paradise' },
    },
  }],
})

const mousingOver = ref(true)
const showGallery = ref(false)

const isGallery = computed(() => {
  return (
    props.fields.galleryImage1.value?.[0]?.url
    || props.fields.galleryImage2.value?.[0]?.url
    || props.fields.galleryImage3.value?.[0]?.url
    || props.fields.galleryImage4.value?.[0]?.url
    || props.fields.galleryImage5.value?.[0]?.url
  )
})

const gallery = computed(() => {
  const gallery = []
  if (props.fields.galleryImage1.value?.[0]?.url)
    gallery.push(props.fields.galleryImage1.media)

  if (props.fields.galleryImage2.value?.[0]?.url)
    gallery.push(props.fields.galleryImage2.media)

  if (props.fields.galleryImage3.value?.[0]?.url)
    gallery.push(props.fields.galleryImage3.media)

  if (props.fields.galleryImage4.value?.[0]?.url)
    gallery.push(props.fields.galleryImage4.media)

  if (props.fields.galleryImage5.value?.[0]?.url)
    gallery.push(props.fields.galleryImage5.media)

  return gallery
})

const { onLinkCLick } = useAtlantisLink()
</script>

<template>
  <div>
    <div class="px-6 sm:container mx-auto relative">
      <div
        @mouseenter="mousingOver = true"
        @mouseleave="mousingOver = false"
      >
        <div
          v-if="fields.showBlueCard.value"
          class="absolute top-0 left-0 lg:-translate-y-24 lg:-translate-x-6 p-4 md:p-8 md:w-36 h-46 bg-glueblue-700 text-white hotel-theme-bg hotel-theme-fg z-10 leading-relaxed flex flex-col items-center"
        >
          <div class="uppercase font-light font-sans tracking-widest text-center text-2xs">
            {{ fields.blueCardTop.value }}
          </div>
          <div class="border-r border-white h-8 opacity-50 my-1" />
          <div class="font-light font-serif italic text-lg md:text-xl tracking-widest">
            {{ fields.blueCardBottom.value }}
          </div>
        </div>
        <div
          class="relative z-0 grid lg:grid-cols-12 bg-white"
        >
          <div
            class="overflow-hidden relative flex aspect-video lg:aspect-auto"
            :class="{
              'lg:col-span-8 xl:col-span-9': fields.size.value === 'small',
              'lg:col-start-5 xl:col-start-4': fields.imageRight.value && fields.size.value === 'small',
              'lg:col-span-7 xl:col-span-8': fields.size.value === 'medium',
              'lg:col-start-6 xl:col-start-5': fields.imageRight.value && fields.size.value === 'medium',
              'lg:col-span-6': fields.size.value === 'large',
              'lg:col-start-7': fields.imageRight.value && fields.size.value === 'large',
            }"
          >
            <VoixMedia :field="fields.image" class="w-full h-full object-cover" />
            <button
              v-if="isGallery"
              class="text-white absolute bottom-0 right-0 mr-8 mb-8"
              @click="showGallery = true"
            >
              <IconsPlusButton style="width: 22px; height: 22px" />
            </button>
            <GlueGalleriesModalGallery
              v-if="isGallery"
              :show="showGallery"
              :gallery="gallery[0]"
              small-key="small"
              large-key="large"
              @close="showGallery = false"
            />
          </div>
          <div
            class="lg:row-start-1 px-12 flex flex-col justify-between"
            :class="{
              'lg:col-span-4 xl:col-span-3': fields.size.value === 'small',
              'lg:col-span-5 xl:col-span-4': fields.size.value === 'medium',
              'lg:col-span-6 xl:col-span-6': fields.size.value === 'large',
              'lg:col-start-9 xl:col-start-10': !fields.imageRight.value && fields.size.value === 'small',
              'lg:col-start-8 xl:col-start-9': !fields.imageRight.value && (fields.size.value === 'medium'),
              'lg:col-start-7 xl:col-start-7': !fields.imageRight.value && (fields.size.value === 'large'),
              'lg:py-24': fields.largeMargin.value,
              'lg:py-10': !fields.largeMargin.value,
              'text-left': fields.textAlignment.value === 'left',
              'text-right': fields.textAlignment.value === 'right',
            }"
          >
            <div class="py-8">
              <div
                class="w-54 mb-6"
                :class="{
                  'border-b border-gray-300': fields.titleUnderline.value,
                }"
              >
                <div
                  class="uppercase font-sans font-light leading-snug text-gray-800 text-3xl"
                >
                  {{ fields.title.value }}
                </div>
                <div class="font-serif font-light text-red-500 italic mb-4 text-2xl tracking-wider">
                  {{ fields.subtitle.value }}
                </div>
              </div>
              <div
                class="text-gray-500 font-sans2 text-15 leading-relaxed wysiwyg"
                v-html="fields.copy.value"
              />
            </div>
            <div class="flex flex-col items-start space-y-4 mt-8 pb-8">
              <div
                v-if="fields.link.enabled"
                class="flex space-x-6"
                :class="{ 'justify-end': fields.textAlignment.value === 'right' }"
              >
                <a
                  v-if="fields.link.value?.href && fields.linkMode.value === 'linedraw'"
                  :href="fields.link.value?.href"
                  class="special-learnmore uppercase text-xs font-sans text-glueblue-400 border-b border-glueblue-400 hover-draw-border pb-1"
                  :class="{ 'w-full': fields.textAlignment.value === 'center' }"
                  @click="onLinkCLick(fields.link.value?.href)"
                >
                  {{ fields.link.value.text }}
                </a>
                <a
                  v-if="fields.link.value?.href && fields.linkMode.value === 'ghost'"
                  :href="fields.link.value?.href"
                  class="btn-ghost"
                  :class="{ 'w-full': fields.textAlignment.value === 'center' }"
                  @click="onLinkCLick(fields.link.value?.href)"
                >
                  {{ fields.link.value.text }}
                </a>
              </div>
              <div
                v-if="fields.link2.enabled"
                class="flex justify-start space-x-6"
                :class="{ 'justify-end': fields.textAlignment.value === 'right' }"
              >
                <a
                  v-if="fields.link2.value?.href && fields.linkMode2.value === 'linedraw'"
                  :href="fields.link2.value?.href"
                  class="special-learnmore uppercase text-xs font-sans text-glueblue-400 border-b border-glueblue-400 hover-draw-border pb-1"
                  :class="{ 'w-full': fields.textAlignment.value === 'center' }"
                  @click="onLinkCLick(fields.link2.value?.href)"
                >
                  {{ fields.link2.value.text }}
                </a>
                <a
                  v-if="fields.link2.value?.href && fields.linkMode2.value === 'ghost'"
                  :href="fields.link2.value?.href"
                  class="btn-ghost"
                  :class="{ 'w-full': fields.textAlignment.value === 'center' }"
                  @click="onLinkCLick(fields.link2.value?.href)"
                >
                  {{ fields.link2.value.text }}
                </a>
              </div>
              <BookingBookNow
                v-if="fields.showBookNow.value"
                :cobrand="fields.cobrand.value"
                location="booking-card"
                disableable
              >
                <button
                  class="mt-6 tracking-wider w-full p-3 text-xs bg-white text-black border border-gray-900 hover:hotel-theme-bg hover:hotel-theme-fg"
                >
                  {{ fields.overrideBookNowLabel.enabled ? fields.overrideBookNowLabel.value : 'BookNow' }}
                </button>
              </BookingBookNow>

              <a
                v-if="fields.secondaryLink.value?.href && fields.secondaryLinkMode.value === 'linedraw'"
                :href="fields.secondaryLink.value?.href"
                class="btn-ghost"
                @click="onLinkCLick(fields.secondaryLink.value?.href)"
              >
                {{ fields.secondaryLink.value.text }}
              </a>
              <a
                v-if="fields.secondaryLink.value?.href && fields.secondaryLinkMode.value === 'ghost'"
                :href="fields.secondaryLink.value?.href"
                class="btn-ghost"
                @click="onLinkCLick(fields.secondaryLink.value?.href)"
              >
                {{ fields.secondaryLink.value.text }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
