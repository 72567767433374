<script>
import { usePageStore } from '@voix/store/pageStore'
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'BookingForm',
  props: {
    dropdown: {
      type: Boolean,
      default: false,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    special: {
      type: Number,
      default: null,
    },
    location: {
      type: String,
      default: 'generic',
    },
    origin: {
      type: String,
      default: 'http://AtlantisBahamas.com/',
    },
    cobrand: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      el: null,
      currentPage: null,
      errors: [],
      alerts: [],
      showFlightOption: true,
      showHotelOption: true,
      hotels: [446810, 533303, 449266, 472864, 449267, 513177],
      booking: {
        type: 'hotel',
        airport: '',
        range: {
          start: new Date(),
          end: new Date(),
        },
        hotel: { id: null, name: null },
        rooms: [
          {
            numAdults: 2,
            numSeniors: 0,
            numInfantsInSeats: 0,
            numInfantsInLaps: 0,
            children: 0,
            childAges: [],
            childPositions: [],
          },
        ],
        tracking: {
          nav: null,
          discount_code: null,
          discount_code_air: null,
          utm_source: null,
          utm_medium: null,
          utm_campaign: null,
          specialid: null,
          special_id_air: null,
        },
      },
      childAgesStyles: {},
      engadgementEventLogged: false,
      tempAirport: null,
      track: null,
      agencyName: null,
    }
  },

  computed: {
    // Need to figureout cobrandOverrides
    // ...mapState(['cobrandOverride']),

    totalGuests() {
      let total = 0

      Object.keys(this.booking.rooms).forEach((i) => {
        total
          += this.booking.rooms[i].childAges.length
            + Number.parseInt(this.booking.rooms[i].numAdults, 0)
      })
      return total
    },

    showMarriottBooking() {
      if (this.currentPage.site.settings.additionalSettings) {
        return this.currentPage.site.settings.additionalSettings
          .showMarriottBooking
      }
      return false
    },

    roomInfo() {
      const rooms = { rooms: JSON.parse(JSON.stringify(this.booking.rooms)) }

      for (let i = 0; i < rooms.rooms.length; i += 1) {
        const spliceAges = []

        for (let j = 0; j < rooms.rooms[i].childPositions.length; j += 1) {
          const position = rooms.rooms[i].childPositions[j]
          if (rooms.rooms[i].childAges[j] < 2) {
            if (position === 'inlap')
              rooms.rooms[i].numInfantsInLaps += 1

            if (position === 'inseat')
              rooms.rooms[i].numInfantsInSeats += 1

            spliceAges.unshift(j)
          }
        }
        for (let k = 0; k < spliceAges.length; k += 1) {
          const indexToSplice = spliceAges[k]
          rooms.rooms[i].childAges.splice(indexToSplice, 1)
        }

        delete rooms.rooms[i].children
        delete rooms.rooms[i].childPositions
      }
      return JSON.stringify(rooms)
    },

    startDate() {
      if (this.booking.range)
        return dayjs(this.booking.range.start).format('MM/DD/YYYY')

      return null
    },

    endDate() {
      if (this.booking.range)
        return dayjs(this.booking.range.end).format('MM/DD/YYYY')

      return null
    },

    /*
     * Expands the bottom form when children are selected
     */
    childrenWidth() {
      for (let i = 0; i < this.booking.rooms.length; i += 1) {
        const room = this.booking.rooms[i]
        if (room.children > 0 && !this.horizontal)
          return 'min-width:80vw'
      }
      return null
    },

    isFormValid() {
      if (
        this.booking.type === 'flightAndHotel'
        && (this.booking.airport === null || this.booking.airport === '')
      ) {
        return false
      }

      if (this.booking.range) {
        const start = dayjs(this.booking.range.start)
        const end = dayjs(this.booking.range.end)
        if (start.format('M-D-YYYY') === end.format('M-D-YYYY'))
          return false
      }

      for (let i = 0; i < this.booking.rooms.length; i += 1) {
        const room = this.booking.rooms[i]

        for (let j = 0; j < room.childAges.length; j += 1) {
          if (!room.childAges[j])
            return false
        }
      }

      return true
    },

    start() {
      if (this.booking.range)
        return this.booking.range.start

      return null
    },

    end() {
      if (this.booking.range)
        return this.booking.range.end

      return null
    },

    formClasses() {
      let classes = ''

      if (this.dropdown) {
        classes += 'absolute top-0 bg-white px-10 pb-8 pt-12 mt-10 min-w-96 translate-x-10 md:translate-x-0 '

        if (this.el && window) {
          const formBounds = this.el.getBoundingClientRect()

          if (formBounds.right > window.innerWidth)
            classes += ' right-0'
        }
      }

      return classes
    },

    alertCollisions() {
      const alertCollisions = []
      this.alerts.some((alert) => {
        if (
          this.dateRangeOverlaps(
            dayjs(alert.start_date).add(1, 'day').toDate(),
            dayjs(alert.end_date).toDate(),
            this.start,
            this.end,
          )
        ) {
          alertCollisions.push(alert)
        }

        return alertCollisions.length > 0
      })

      // Sort the collisions by min_nights
      // alertCollisions.sort((a, b) => {
      //   if (a.min_nights < b.min_nights)
      //     return -1

      //   if (a.min_nights > b.min_nights)
      //     return 1

      //   return 0
      // })

      return alertCollisions
    },

    alertErrorMessage() {
      let errorMsg = ''
      if (this.alertCollisions.length > 0) {
        for (const alert of this.alertCollisions) {
          if (alert.min_nights && this.numberOfNights < alert.min_nights)
            errorMsg += `Based on your selection you must select a minimum of ${alert.min_nights} nights.`
        }
      }
      return errorMsg
    },

    numberOfNights() {
      return dayjs(this.end).diff(dayjs(this.start), 'days')
    },
  },

  watch: {
    booking: {
      handler(val) {
        if (val.type === 'hotel' && this.booking.airport) {
          this.tempAirport = this.booking.airport
          this.booking.airport = null
        }
        // if (val.type === 'flightAndHotel' && this.tempAirport)
        //   this.booking.airport = this.tempAirport

        // sets defaults for children
        Object.keys(val.rooms).forEach((i) => {
          const { children } = val.rooms[i]
          if (children !== val.rooms[i].childAges.length) {
            const newAgesArr = []
            const newPositions = []
            for (let j = 0; j < children; j += 1) {
              if (typeof val.rooms[i].childAges[j] !== 'undefined') {
                newAgesArr[j] = val.rooms[i].childAges[j]
                newPositions[j] = val.rooms[i].childPositions[j]
              }
              else {
                newAgesArr[j] = 1
                newPositions[j] = 'inseat'
              }
            }
            val.rooms[i].childAges = newAgesArr
            val.rooms[i].childPositions = newPositions
          }

          if (Number.parseInt(val.rooms[i].numAdults) + Number.parseInt(val.rooms[i].children) > 4 && this.errors.length < 1) {
            this.showError(
              'Your results may be limited due to your party size. Max capacity for most rooms are 4 guests & 1 child under 4. For more results please add an additional room.',
            )
          }
        })

        this.retrieveAlerts()
      },
      deep: true,
    },
  },

  mounted() {
    const pageStore = usePageStore()
    this.currentPage = pageStore.currentPage

    this.el = this.$refs.bookingForm
    // Set start to tomorrow
    // this.booking.range.start = dayjs().add(1, "days").toDate();
    // this.updateBookingStartDate();

    // TODO: Need to add this in after we get page variables in
    this.retrieveTracking().then(() => {
      if (!this.booking.tracking.override_calendar_alerts)
        this.retrieveAlerts()
      else
        this.loadTrackingAlertOverrides()

      this.setParamsFromCookiedSearch()
    })

    this.setAgentTracking()

    this.showErrorsFromSwitchfly()
  },

  methods: {
    setAirport(airport) {
      this.booking.airport = airport
    },

    inAHotelSection() {
      const parts = window.location.pathname.split('/')
      const hotelSlugs = [
        'thecoveatlantis',
        'royaltowers',
        'coraltowers',
        'thereefatlantis',
        'beachtower',
        'harborsideresort',
      ]
      let inRooms = false
      for (const i in parts) {
        if (inRooms && hotelSlugs.includes(parts[i]))
          return true

        if (parts[i] === 'rooms')
          inRooms = true
      }
      return false
    },

    cookiedHotelIsNotInHotelsList(bookingObj) {
      if (Object.prototype.hasOwnProperty.call(bookingObj, 'hotel')) {
        const inList = this.hotels.filter((hotelId) => {
          return hotelId === bookingObj.hotel.id
        })
        return inList.length === 0
      }
    },

    updateBookingStartDate() {
      const start = dayjs(this.booking.range.start)
      const twoDaysFromStart = start.add(5, 'days').toDate()

      this.booking.range.end = twoDaysFromStart
    },

    setAgentTracking() {
      const agencyCookie = useCookie('atlantis-travel-agent')
      if (agencyCookie.value) {
        const agentInfo = agencyCookie.value
        this.track = agentInfo.track_num ? agentInfo.track_num : null
        this.agencyName = agentInfo.agency_name ? agentInfo.agency_name : null
      }
    },

    submit() {
      this.validateForm()
        .then(() => {
          if (this.currentPage.site_id === 1) {
            const bookingSubmittionCookie = useCookie('booking-submission-id')
            const bookingSubmittionFrom = useCookie('booking-submitted-from')
            bookingSubmittionCookie.value = uuidv4()

            this.logToDataLayer()
            const formParams = new URLSearchParams(
              new FormData(this.$refs.masteroftheuniverse),
            ).toString()

            const from = window.location.href

            bookingSubmittionFrom.value = from

            $voixFetch(`/user-booking-interstitial?${formParams}&submission_id=${bookingSubmittionCookie.value}&from=${from}`)
          }

          const bookingSubmittedFromCookie = useCookie(
            'booking-submitted-from',
          )
          bookingSubmittedFromCookie.value = window.location.href

          const bookingParamsCookie = useCookie('booking-params')
          bookingParamsCookie.value = JSON.stringify(this.booking)

          const bookingParamsWaitlistCookie = useCookie(
            'booking-params-waitlist',
          )
          bookingParamsWaitlistCookie.value = JSON.stringify(this.booking)

          this.$refs.masteroftheuniverse.submit()
        })
        .catch((errors) => {
          for (const error of errors)
            this.showError(error)
        })
    },

    showError(error) {
      this.errors.push(error)
    },

    validateForm() {
      return new Promise((resolve, reject) => {
        const errors = []
        this.errors = []

        const start = dayjs(this.booking.range.start)
        const end = dayjs(this.booking.range.end)

        if (this.booking.type === 'flightAndHotel') {
          if (this.booking.airport === '' || this.booking.airport === null)
            errors.push('You must select an airport.')

          if (this.totalGuests > 9) {
            errors.push(
              'Party too large. Flights are limited to 9 passengers or less.',
            )
          }
          const today = dayjs()
          if (start.diff(today, 'days') < 1) {
            errors.push(
              'Please call 1-800 Atlantis for flights departing within 48 hours.',
            )
          }
        }

        if (this.booking.tracking.book_limit_min_days === null) {
          if (end.diff(start, 'days') < 1) {
            errors.push(
              'Minimum 1 night stay required. Please adjust your search to find results.',
            )
          }
        }
        else if (
          end.diff(start, 'days') < this.booking.tracking.book_limit_min_days
        ) {
          errors.push(
            `Minimum ${this.booking.tracking.book_limit_min_days} night stay required. Please adjust your search to find results.`,
          )
        }

        if (this.booking.tracking.book_limit_max_days === null) {
          if (end.diff(start, 'days') > 30) {
            errors.push(
              'Please call 1-800 Atlantis for bookings greater than 30 days.',
            )
          }
        }
        else if (
          end.diff(start, 'days') > this.booking.tracking.book_limit_max_days
        ) {
          errors.push(
            `Stays over ${this.booking.tracking.book_limit_max_days} nights not permitted. Please adjust your search to find results.`,
          )
        }

        // if (this.alertErrorMessage)
        //   errors.push(this.alertErrorMessage)

        if (errors.length > 0)
          reject(errors)
        else resolve()
      })
    },

    retrieveTracking() {
      let apiUrl = `/api/devise/pages/${this.currentPage.id}/booking-engine-parameters`

      if (this.special !== null)
        apiUrl += `?special_id=${this.special}`

      if (this.cobrand !== null)
        apiUrl += `?cobrand_name=${this.cobrand}`

      if (this.currentPage.site_id === 1 && window.dataLayer) {
        window.dataLayer.push({
          event: 'e_bookNow',
          locationBooked: this.location,
          newSite: true,
        })
      }

      return $voixFetch(apiUrl).then((response) => {
        this.booking.tracking = Object.assign({}, response)

        if ('hotels' in response && response.hotels) {
          // change response.hotels array of objects to array of ids
          const responseHotels = response.hotels.map(hotel => hotel.id)
          this.hotels = responseHotels

          const hotels = this.hotels.filter((hotelId) => {
            return hotelId !== 449266
          })

          if (hotels.length === 1) {
            switch (hotels[0]) {
              case 446810:
                this.booking.hotel = { id: 446810, name: 'The Cove' }
                break
              case 533303:
                this.booking.hotel = { id: 533303, name: 'The Royal' }
                break
              case 449267:
                this.booking.hotel = { id: 449267, name: 'The Coral' }
                break
              case 513177:
                this.booking.hotel = { id: 513177, name: 'The Reef' }
                break
              case 449266:
                this.booking.hotel = { id: 449266, name: 'The Beach' }
                break
              case 472864:
                this.booking.hotel = { id: 472864, name: 'Harborside Resort' }
                break
            }
          }
        }

        if (
          Object.prototype.hasOwnProperty.call(response, 'book_disable_flights')
          && response.book_disable_flights
        ) {
          this.showFlightOption = false
          if (this.booking.type === 'flightAndHotel') {
            this.booking.type = 'hotel'
            this.booking.airport = null
          }
        }

        if (
          Object.prototype.hasOwnProperty.call(response, 'book_disable_hotel')
          && response.book_disable_hotel
        ) {
          this.showHotelOption = false
          if (this.booking.type === 'hotel')
            this.booking.type = 'flightAndHotel'
        }

        if (this.cobrandOverride)
          this.booking.tracking.nav = this.cobrandOverride

        this.setCobrandMinMaxDates()
      })
    },

    setCobrandMinMaxDates() {
      if (this.booking.tracking.book_limit_min_date !== null) {
        const trackMinDate = dayjs(this.booking.tracking.book_limit_min_date)
        if (trackMinDate.diff(dayjs(), 'days') > 0) {
          this.booking.range.start = trackMinDate.toDate()

          const trackingMaxDate = dayjs(this.booking.tracking.book_limit_max_date).toDate()
          const daysFromStart = dayjs(this.booking.range.start)
            .add(5, 'days')
            .toDate()

          // If trackingMaxDate is less than daysFromStart use trackingMaxDate
          this.booking.range.end = trackingMaxDate < daysFromStart ? trackingMaxDate : daysFromStart
        }
      }
    },

    retrieveAlerts() {
      let apiUrl = `/api/devise/pages/${this.currentPage.id}/calendar-alerts?`
      const args = []

      if (this.booking.hotel.id)
        args.push(`tower_id=${this.booking.hotel.id}`)

      if (this.booking.tracking.id)
        args.push(`cobrand_id=${this.booking.tracking.id}`)

      apiUrl += args.join('&')

      $voixFetch(apiUrl).then((response) => {
        this.alerts = response
      })
    },

    loadTrackingAlertOverrides() {
      this.alerts = this.booking.tracking.calendar_alerts
    },

    setNumberOfRooms(e) {
      const rooms = e.target.value
      const currentRooms = this.booking.rooms.length

      if (currentRooms < rooms) {
        const numberToAdd = rooms - currentRooms
        for (let i = 0; i < numberToAdd; i += 1)
          this.booking.rooms.push(Object.assign({}, this.blankRoom))
      }
      else {
        const numberToSplice = Math.abs(rooms - currentRooms)
        this.booking.rooms.splice(1, numberToSplice)
      }
    },

    getAlertColor(alertType) {
      if (alertType === 'restriction')
        return 'yellow'

      if (alertType === 'sold_out')
        return 'red'

      return 'teal'
    },

    logToDataLayer() {
      try {
        const funnel = this.booking.type === 'hotel' ? 'hotel' : 'air_room'

        // window.dataLayer.push({
        //   event: 'e_checkout',
        //   funnelType: funnel,
        //   stepName: 'reserve now',
        // });

        let numberOfAdults = 0
        let numberOfChildren = 0
        Object.keys(this.booking.rooms).forEach((i) => {
          numberOfAdults += this.booking.rooms[i].numAdults
          numberOfChildren += this.booking.rooms[i].children
        })

        if (window.dataLayer) {
          window.dataLayer.push({
            event: 'e_reserveNow',
            funnelType: funnel,
            checkinDate: this.gaFormat(this.booking.range.start),
            checkoutDate: this.gaFormat(this.booking.range.end),
            numberRooms: this.booking.rooms.length,
            numberAdults: numberOfAdults,
            numberKids: numberOfChildren,
            hotel: this.booking.hotel.name,
            hotelId: this.booking.hotel.id,
            newSite: true,
          })
        }
      }
      catch (e) {
        console.error(e)
      }
    },

    gaFormat(date) {
      const year = date.getFullYear()
      let month = (1 + date.getMonth()).toString()
      month = month.length > 1 ? month : `0${month}`
      let day = date.getDate().toString()
      day = day.length > 1 ? day : `0${day}`

      return `${month}/${day}/${year}`
    },

    setParamsFromCookiedSearch() {
      try {
        const bookingParamsWaitlistCookie = useCookie(
          'booking-params-waitlist',
        )
        if (bookingParamsWaitlistCookie.value) {
          const bookingObj = JSON.parse(bookingParamsWaitlistCookie.value)
          bookingObj.range.start = new Date(bookingObj.range.start)
          bookingObj.range.end = new Date(bookingObj.range.end)
          bookingObj.tracking = this.booking.tracking

          if (!this.showFlightOption) {
            bookingObj.type = 'hotel'
            bookingObj.airport = null
          }

          if (!this.showHotelOption)
            bookingObj.type = 'flightAndHotel'

          if (
            Object.prototype.hasOwnProperty.call(bookingObj, 'hotel')
            || this.inAHotelSection()
            || this.cookiedHotelIsNotInHotelsList(bookingObj)
          ) {
            // if cookied search is missing hotel
            // if we are in a hotel section so lets not use the cookie
            // if the cookied hotel is not in the "hotels list" so let's not use it
            // then set the cookied hotel value to the current hotel value
            bookingObj.hotel = this.booking.hotel
          }

          if (!Object.prototype.hasOwnProperty.call(bookingObj, 'rooms'))
            bookingObj.rooms = this.booking.rooms

          this.booking = bookingObj
        }
      }
      catch (err) {
        console.warn('Issue with booking params. See BookingForm.')
      }
    },

    showErrorsFromSwitchfly() {
      const urlParams = new URLSearchParams(window.location.search)
      const errorMessage = urlParams.get('ERROR_MESSAGE')
      // this.showError(errorMessage || 'No rooms found with selected options.')
    },

    dateRangeOverlaps(a_start, a_end, b_start, b_end) {
      if (a_start <= b_start && b_start <= a_end)
        return true // b starts in a
      if (a_start <= b_end && b_end <= a_end)
        return true // b ends in a
      if (b_start < a_start && a_end < b_end)
        return true // a in b
      return false
    },
  },
}
</script>

<template>
  <div
    ref="bookingForm"
    :class="formClasses" style="
      max-width: 940px;
      background-color: rgba(255, 255, 255, 0.9);
      backdrop-filter: blur(5px);
    "
  >
    <div
      class="mb-4" :class="{
        'flex flex-col md:flex-row justify-between': !dropdown,
        'flex flex-col': dropdown,
      }"
    >
      <div
        class="font-sans uppercase font-light tracking-widest text-gray-600" :class="{
          ' text-xl': !dropdown,
          ' text-lg': dropdown,
        }"
      >
        Make a Reservation
      </div>
      <div class="flex flex-row justify-between items-end w-56 font-sans2 mb-2" :class="{ 'w-full mt-6': dropdown }">
        <!-- Hotel -->
        <button
          v-if="showHotelOption" class="flex items-center space-y-1 pb-1 hotel-theme-fg-inverse uppercase font-light text-xs text-gray-800 focus:outline-none"
          :class="{
            'border-b border-glueblue-700 hotel-theme-border-inverse':
              booking.type === 'hotel',
            'border-b border-white': booking.type !== 'hotel',
            'flex-col': dropdown,
            'flex-row': !dropdown,
          }"
          @click="booking.type = 'hotel'"
        >
          <SvgsBuilding
            class="text-glueblue-700 hotel-theme-fg-inverse"
            :class="{
              'mr-2': !dropdown,
            }"
            style="width: 19px; height: 15px"
          />
          <div class="flex-shrink hotel-theme-fg-inverse">
            Hotel
          </div>
        </button>

        <!-- Flight -->
        <button
          v-if="showFlightOption" class="flex items-center space-y-1 pb-1 uppercase font-light text-xs focus:outline-none text-gray-800"
          :class="{
            'border-b border-glueblue-700 hotel-theme-border-inverse':
              booking.type === 'flightAndHotel',
            'border-b border-white': booking.type !== 'flightAndHotel',
            'flex-col': dropdown,
            'flex-row': !dropdown,
          }" @click="booking.type = 'flightAndHotel'"
        >
          <SvgsAirplane
            class="text-glueblue-700 hotel-theme-fg-inverse"
            :class="{
              'mr-2': !dropdown,
            }"
            style="min-width: 21px; height: 19px"
          />
          <div class="flex-shrink hotel-theme-fg-inverse">
            Hotel + Flight
          </div>
        </button>

        <!-- Activities -->
        <a
          v-if="dropdown" class="flex flex-col items-center pb-1 uppercase font-light text-xs focus:outline-none text-gray-800"
          :class="{
            'border-b border-glueblue-700 hotel-theme-border-inverse':
              booking.type === 'restaurant',
            'border-b border-white': booking.type !== 'restaurant',
          }"
          href="/already-booked-things-to-do-atlantis"
        >
          <!-- <Icon class="w-8 h-8 text-glueblue-700 relative top-[4px]" name="arcticons:dolphinemulator" /> -->
          <Icon class="w-6 h-6 mb-1 text-glueblue-700 relative top-[4px]" name="fluent:person-running-20-regular" />
          <span class="flex-shrink hotel-theme-fg-inverse">
            Activities
          </span>
        </a>

        <!-- Dining -->
        <a
          v-if="dropdown" class="flex flex-col items-center space-y-1 pb-1 uppercase font-light text-xs focus:outline-none text-gray-800"
          :class="{
            'border-b border-glueblue-700 hotel-theme-border-inverse':
              booking.type === 'restaurant',
            'border-b border-white': booking.type !== 'restaurant',
          }"
          href="/dining/dining-reservations"
        >
          <svg
            class="text-glueblue-700 hotel-theme-fg-inverse" style="width: 21px; height: 21px" fill="none"
            stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round" stroke-linejoin="round" stroke-width="1"
              d="M21 15.546c-.523 0-1.046.151-1.5.454a2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.704 2.704 0 00-3 0 2.704 2.704 0 01-3 0 2.701 2.701 0 00-1.5-.454M9 6v2m3-2v2m3-2v2M9 3h.01M12 3h.01M15 3h.01M21 21v-7a2 2 0 00-2-2H5a2 2 0 00-2 2v7h18zm-3-9v-2a2 2 0 00-2-2H8a2 2 0 00-2 2v2h12z"
            />
          </svg>
          <span class="flex-shrink hotel-theme-fg-inverse" :class="{ hidden: dropdown }">
            Restaurant Reservation
          </span>
          <span class="flex-shrink hotel-theme-fg-inverse" :class="{ hidden: !dropdown }">
            Dine
          </span>
        </a>
      </div>
    </div>

    <div v-if="agencyName" class="font-sans2 mb-4 -mt-2 text-xs text-glueblue-600 bg-white px-4 py-2 rounded-sm">
      <span class="font-light">Booking under travel agency:</span>
      {{ agencyName }}
    </div>

    <div
      v-if="booking.type !== 'restaurant'" :class="{
        'flex flex-col lg:flex-row': !dropdown,
        'flex flex-col items-end': dropdown,
      }"
    >
      <BookingHotels
        v-model="booking.hotel" :hotels="hotels"
        :class="{
          'w-full lg:w-1/4 mb-2 lg:mb-0 lg:pr-2': !dropdown,
          'w-full': dropdown,
        }"
      />

      <ClientOnly>
        <BookingCalendar
          v-model="booking" :class="{
            'w-full lg:w-1/3 lg:pr-2 mb-2 lg:mb-0': !dropdown,
            'w-full mt-2': dropdown,
          }" :placement="dropdown ? 'bottom' : 'bottom'" :alerts="alerts"
        />
      </ClientOnly>

      <BookingFlight
        v-if="booking.type === 'flightAndHotel'" v-model="booking.airport" :class="{
          'w-full lg:w-1/5 lg:pr-2 mb-2 lg:mb-0': !dropdown,
          'w-full mt-2': dropdown,
        }" @set-airport="setAirport"
      />

      <BookingGuests
        v-model="booking" :class="{
          'w-full lg:w-1/4 mb-2 lg:mb-0 lg:pr-2 flex': !dropdown,
          'w-full flex mt-2': dropdown,
        }"
      />

      <button
        class="pointer-events-auto relative glue-btn hotel-theme-bg hotel-theme-fg text-sm uppercase tracking-widest flex-grow p-3 px-16"
        :class="{
          'cursor-not-allowed': !isFormValid,
          'mt-6 w-48 border border-gray-400 text-gray-800': dropdown,
          'bg-glueblue-700 text-white': !dropdown,
        }"
        :disabled="!isFormValid"
        @click.prevent="submit"
      >
        Search
      </button>
    </div>
    <div v-else>
      <BookingOpenTable :small="dropdown" />
    </div>
    <ul v-if="errors.length > 0" class="font-sans mb-4">
      <li v-for="(error, key) in errors" :key="key" class="p-4 my-2 bg-red-500 text-white rounded font-sans2 text-sm">
        {{ error }}
      </li>
    </ul>

    <!-- <div class="absolute inset-0 translate-y-full bg-white p-4 overflow-scroll">
      <pre>{{ booking }}</pre>
    </div> -->

    <ClientOnly>
      <form
        ref="masteroftheuniverse" action="https://secure.atlantisbahamas.com/travel/arc_waiting.cfm" method="GET"
      >
        <input type="hidden" name="room_info" :value="roomInfo">
        <input type="hidden" name="origin" :value="origin">
        <input v-if="track != null" type="hidden" name="track" :value="track">
        <input type="hidden" name="area1" :value="booking.airport">
        <input type="hidden" name="hotel_id" :value="booking.hotel.id">
        <input type="hidden" name="all_hotels" value="true">
        <input type="hidden" name="area2" value="NAS">
        <input v-if="booking.type === 'hotel'" type="hidden" name="air_room_car_radio" value="room">
        <input v-else type="hidden" name="air_room_car_radio" value="air_room">
        <input type="hidden" name="displayarea1" :value="booking.airport">
        <input type="hidden" name="num_rooms" :value="booking.rooms.length">
        <input type="hidden" name="date1" :value="startDate">
        <input type="hidden" name="date2" :value="endDate">
        <input type="hidden" name="nav" :value="booking.tracking.nav">
        <input type="hidden" name="discount_code" :value="booking.tracking.discount_code">
        <input type="hidden" name="discount_code_air" :value="booking.tracking.discount_code_air">
        <input type="hidden" name="utm_source" :value="booking.tracking.utm_source">
        <input type="hidden" name="utm_medium" :value="booking.tracking.utm_medium">
        <input type="hidden" name="utm_content" :value="booking.tracking.utm_content">
        <input type="hidden" name="utm_campaign" :value="booking.tracking.utm_campaign">
        <input type="hidden" name="specialid" :value="booking.tracking.special_id">
        <input type="hidden" name="special_id_air" :value="booking.tracking.special_id_air">
      </form>
    </ClientOnly>
  </div>
</template>
