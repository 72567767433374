<script setup lang="ts">
const props = defineProps<{
  error: {
    statusCode: number
    message: string
    stack: string
  }
}>()

if (props.error.statusCode !== 404) {
  console.error('Error Message', props.error.message)
  console.error('Error Stack', props.error.stack)
}

const sliderImageShortFields = {
  image: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/styled/62bb07c4b973232c729fcab2da447a99' },
  mobileImage: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/styled/62bb07c4b973232c729fcab2da447a99' },
  link: { enabled: false, value: {} },
}

const generalTitleAndCopyFields = {
  mediaAbove: { enabled: false },
  description: { value: '<p>Let us see if we can get you back to what you were looking for. You can use the menu at the top of this page to get back on track or try any of the popular sections suggested below. We have also sent a report to our team detailing the page that caused an error so we can get it fixed as soon as possible.<\/p>', enabled: true },
  descriptionAlignment: { value: 'center' },
  descriptionSize: { value: 'large' },
  position: { value: 'center' },
  subtitle: { value: 'Our Apologies' },
  textAlign: { value: 'center' },
  title: { value: 'Sorry, we couldn\'t find that page' },
  titleSize: { value: 'medium' },
  width: { value: 'large' },
  contain: { value: false },
  flex: { value: false },
  link: { enabled: false },
  link2: { enabled: false },
  colorOverride: { value: null, enabled: false },
  bookingButtonLabel: { enabled: false },
}

const backgroundsRepeatingBackground = {
  background: { value: 'floral' },
  bottomOverlap: { value: 0 },
  topInnerPadding: { default: 1 },
  innerContainer: { value: true },
}

const diningFeatureFields = {
  image: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/atlantis-aquaventure-royal-baths-pool-woman-standing-at-edge-of-pool.jpg' },
  imageAnimatedGif: { value: false },
  size: { value: 'medium' },
  imageRight: { value: false },
  showBlueCard: { value: false },
  blueCardTop: { value: false },
  blueCardBottom: { value: false },
  subtitle: { value: '' },
  title: { value: 'Atlantis Homepage' },
  titleUnderline: { value: true },
  textAlignment: { value: 'left' },
  copy: { value: '<p>The Atlantis Homepage is a great place to start when looking to learn about Atlantis, it\'s offerings, and how you and your family can have the adventure of a lifetime.</p>' },
  copyVerticalPadding: { value: 0 },
  link: { enabled: true, value: { text: 'Atlantis Homepage', href: 'https://www.atlantisbahamas.com/' } },
  linkMode: { value: 'ghost' },
  link2: { enabled: false, value: {} },
  linkMode2: { value: 'ghost' },
  showBookNow: { value: false },
  overrideBookNowLabel: { enabled: false, value: '' },
  cobrand: { value: '' },
  secondaryLink: { enabled: false, value: {} },
  largeMargin: { value: true },
  galleryImage1: { enabled: false, value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/media/atlantis-aquaventure-royal-baths-pool-woman-standing-at-edge-of-pool.jpg' },
  galleryImage2: { enabled: false, value: '' },
  galleryImage3: { enabled: false, value: '' },
  galleryImage4: { enabled: false, value: '' },
  galleryImage5: { enabled: false, value: '' },
}

const descriptionCardOneFields = {
  size: { value: 'full' },
  image: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/styled/79c9fe55515af0bd8adfa5fc6c952d02' },
  image2: { value: null },
  image3: { value: null },
  mobileImage: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/styled/79c9fe55515af0bd8adfa5fc6c952d02' },
  title: { value: 'Rooms & Towers' },
  borderOn: { value: true },
  copy: { value: 'The stay section of Atlantisbahamas.com has all the information about the rooms here at the Atlantis. Explore each of our five hotels and find the one that is the right fit for your trip.' },
  bookNowLink: { value: null, enabled: false },
  link: { enabled: true, value: { text: 'Rooms & Towers', href: 'https://www.atlantisbahamas.com/rooms', target: '_blank' } },
}

const descriptionCardTwoFields = {
  size: { value: 'full' },
  image: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/styled/5ebbf687e15e9ffb819ed8137fc475d0' },
  image2: { value: null },
  image3: { value: null },
  mobileImage: { value: 'https://atlantis-cms-assets.s3.us-east-2.amazonaws.com/styled/5ebbf687e15e9ffb819ed8137fc475d0' },
  title: { value: 'Dining' },
  borderOn: { value: true },
  copy: { value: 'Learn about our world-class talent, celebrity chefs, and amazing entrees around The Atlantis Resort. Our cuisine comes from all over the world to provide adventure and variety while staying at the resort.' },
  bookNowLink: { value: null, enabled: false },
  link: { enabled: true, value: { text: 'Dining & Restaurants', href: 'https://www.atlantisbahamas.com/dining', target: '_blank' } },
}
if (typeof window === 'object') {
  const { $Mothership } = useNuxtApp()
  $Mothership.logError('error', props.error?.message, window.location.href, props.error)
}
</script>

<template>
  <SlicesSlidersSliderImageShort :fields="sliderImageShortFields" />
  <div class="py-12 border-t-8 " :class="{ 'border-red-500': error.statusCode !== 404, 'border-glueblue-600': error.statusCode === 404 }">
    <SlicesGlueTitlesAndTextGeneralTitleAndCopy :fields="generalTitleAndCopyFields" />
    <SlicesGlueBackgroundsRepeatingBackground :fields="backgroundsRepeatingBackground">
      <div class="mt-12 -translate-y-12 small-container mx-auto flex flex-col space-y-12">
        <SlicesGlueDiningDiningFeature :fields="diningFeatureFields" />
        <div class="grid grid-cols-2 gap-12 -mx-6">
          <SlicesGlueGenericCardsDescriptionCard :fields="descriptionCardOneFields" />
          <SlicesGlueGenericCardsDescriptionCard :fields="descriptionCardTwoFields" />
        </div>
      </div>
    </SlicesGlueBackgroundsRepeatingBackground>
  </div>
</template>
