
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46slug_93nuk8U8yQzk42FLC78KbcEWoj19yIPfe30UjdPicRJRkMeta } from "/home/bun/app/pages/[...slug].vue?macro=true";
import { default as indexiyHTlVBoS8R_45hjVFkQwPbTMi75Z1p2k_458_45EFWq7SzuYMeta } from "/home/bun/app/pages/voix/mona/index.vue?macro=true";
import { default as index6BUd9zO6fkysAPFltedbYmvg2ebElgGCjSJPRTdDx7gMeta } from "/home/bun/app/pages/voix/blogs/index.vue?macro=true";
import { default as indexlUWVkei5p4jLAWMzjbwlfuv1Mgp0AR5x_45eE_bLrktL8Meta } from "/home/bun/app/pages/voix/menus/index.vue?macro=true";
import { default as createE2F7z0fOApsegP9IbrHrT5CY5bksIXOkTdLRViiVPtMMeta } from "/home/bun/app/pages/voix/blogs/create.vue?macro=true";
import { default as indexGF9v9j_45rtM3viiM5rLlAIprM122qF2TI44ViBGZTSIcMeta } from "/home/bun/app/pages/voix/caches/index.vue?macro=true";
import { default as index7pG4k0JKk_45dN86O2IaFUgIk0Ig0UQ22bssJWILEtrfEMeta } from "/home/bun/app/pages/voix/events/index.vue?macro=true";
import { default as indexVrnSwhhhV35d1znV7OXl6xMQm0s2EhHOU1O6XTZDWx8Meta } from "/home/bun/app/pages/voix/hotels/index.vue?macro=true";
import { default as createRjujb25X3pEPOTG8XhuRUXvkRnbsoAPa_45lSbkPKXeyMMeta } from "/home/bun/app/pages/voix/menus/create.vue?macro=true";
import { default as create76QlALixELENZotcQ_45SZP7lLnQyPn3qEci4ORfioQkoMeta } from "/home/bun/app/pages/voix/events/create.vue?macro=true";
import { default as index7kDDsnuopdcyqrZivXLpxSESNJ1KfZVERwccXynkrQAMeta } from "/home/bun/app/pages/voix/cobrands/index.vue?macro=true";
import { default as index3pfJLWwbAPWn8lVdMvwparpCvm_Ax4_45oz7SZvpzjuSUMeta } from "/home/bun/app/pages/voix/specials/index.vue?macro=true";
import { default as _91id_93HEH4TweYl_45XvvGrnFRDjycOVoi6uAD5D9s3s9gq4Jj4Meta } from "/home/bun/app/pages/voix/blogs/edit/[id].vue?macro=true";
import { default as create8wAkx4kIC1YMRUaXRUBHsc2x_ZJ_454qvlqkEQDI6pl9cMeta } from "/home/bun/app/pages/voix/cobrands/create.vue?macro=true";
import { default as _91id_93kNATSnLQIPaj47hnLmctZAm0qUgEv5ogFE_45S21WRrj8Meta } from "/home/bun/app/pages/voix/menus/edit/[id].vue?macro=true";
import { default as indexLnAcoCC30fpJpSHVUtVI_45Aw0Qjj0RazAn8AxNj4rgLEMeta } from "/home/bun/app/pages/voix/mona/logs/index.vue?macro=true";
import { default as createMRmPKo_45195PdGd_45UWAINylW_45ngwZhE0ZuNcDAu6KeuQMeta } from "/home/bun/app/pages/voix/specials/create.vue?macro=true";
import { default as indexPmPpi8oKrHe8XKaDRButzw7huRTaS8b3o3_c3T_45Zpy8Meta } from "/home/bun/app/pages/voix/wait-list/index.vue?macro=true";
import { default as indexixhtCvvx_FajeOenQqzCSR3LcYBkruvvBrkSseKulhcMeta } from "/home/bun/app/pages/voix/error-logs/index.vue?macro=true";
import { default as _91id_93c_45YyfvaYthgrpQV7vbPk5SbhQgrZYwjY6SW1OyxcbQQMeta } from "/home/bun/app/pages/voix/events/edit/[id].vue?macro=true";
import { default as _91id_930pNTsg2YINPkDzb5Op7tl6tJEOn3V3cCn25VXhJQp0EMeta } from "/home/bun/app/pages/voix/hotels/edit/[id].vue?macro=true";
import { default as indexKXaL8UOOuU9CLhXqBKDply2Ou37VlP6OsiQ3WjWwh90Meta } from "/home/bun/app/pages/voix/mona/pages/index.vue?macro=true";
import { default as index5OfAAdokicpys9wjk_45PaJZupljABWoFga0pO28ymsJ4Meta } from "/home/bun/app/pages/voix/movie-times/index.vue?macro=true";
import { default as indexbUKnFz4g8WDah1URX26JdztbknJzIJW2rMdubAX4qUAMeta } from "/home/bun/app/pages/voix/restaurants/index.vue?macro=true";
import { default as _91id_93wHn0T0pwRJLzkxVpYc7Reie6x46NFZ0mtr2k0amWRagMeta } from "/home/bun/app/pages/voix/cobrands/edit/[id].vue?macro=true";
import { default as createrC60is6r_45LvtDj0iOmcwKKHr7ao6zCIsH9T_EvLVKHEMeta } from "/home/bun/app/pages/voix/restaurants/create.vue?macro=true";
import { default as _91id_93Of7SuH6HdQvvgHDAlEY2cGk_45CChOx5guQU9ol_45K_hN4Meta } from "/home/bun/app/pages/voix/specials/edit/[id].vue?macro=true";
import { default as indexLs_45jWVMcTPPROIbptB4nFfMJ0FVimVA_qtA6HPsKnJsMeta } from "/home/bun/app/pages/voix/travel-agents/index.vue?macro=true";
import { default as indexFp2n_jj_45Sp_o3I_b19ud7FlGrFFeeS19NXc9V913FWAMeta } from "/home/bun/app/pages/voix/press-releases/index.vue?macro=true";
import { default as createfOoNQuoW020WcGPpZntvNBn3x3MYmx058LXQv1_45DdL4Meta } from "/home/bun/app/pages/voix/travel-agents/create.vue?macro=true";
import { default as indexmt8GD64_45_6i8pJLi2NEgG_6DMruXSFv6UtDGYfNnCLsMeta } from "/home/bun/app/pages/voix/calendar-alerts/index.vue?macro=true";
import { default as creater26lzrADm6seFs2Pbgyz0Wdxmz_a__zEcldBviR_NtQMeta } from "/home/bun/app/pages/voix/press-releases/create.vue?macro=true";
import { default as _91id_93qt_45EGDpKtXTxDHlPWfOhGUNXedwkfajkvrbLVknHrw4Meta } from "/home/bun/app/pages/voix/restaurants/edit/[id].vue?macro=true";
import { default as _91id_93fn0cNAlgNc0Dxu6aLXP49VThO6BJfVhRTT66_rKBx_45cMeta } from "/home/bun/app/pages/voix/travel-agents/edit/[id].vue?macro=true";
import { default as _91id_93F9zO5wyisJRwuveS8tgs2Tn7a0tpA4_2yev1dNs0_ckMeta } from "/home/bun/app/pages/voix/press-releases/edit/[id].vue?macro=true";
import { default as indexoWRMOcDHzXWOi_45Ft0iCW2Oh_45ZMyX76MUMK_45oaULMryMMeta } from "/home/bun/app/pages/voix/specials-categories/index.vue?macro=true";
import { default as createXkCKiZ4ciwT_ajHOw4wspmxW6DVNDmseTjO7CSNE3S8Meta } from "/home/bun/app/pages/voix/specials-categories/create.vue?macro=true";
import { default as _91id_93ZT0wTRVZUAXTAxBuJAwmJCGYK1qpKa2lBwLbsI0_SZsMeta } from "/home/bun/app/pages/voix/specials-categories/edit/[id].vue?macro=true";
import { default as voix_45debug8q7L7SdE7fIDIDxmWnJVQGaqbvxoYFCTJ45bSS_UDwMMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix-debug.vue?macro=true";
import { default as PreviewxomVcEvCkrNN0wIIUhmdPpwIJyA4vy6swHB6ldlXadQMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Preview.vue?macro=true";
import { default as IndexmGF7j_4556Wz6V2m_455zE4OglmRXrzkfbWCdt_Hx1eWRnYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/settings-menu/Index.vue?macro=true";
import { default as loginYP8MqdiO4yqS94PXpoowUi9PKy2eHXI8HjvZ_45UQSqQYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/login.vue?macro=true";
import { default as callbackYuzij_45oA1SlToE2fdToBSRY9_vfyw_Xw7H4PykbXlLcMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/callback.vue?macro=true";
import { default as studioki1adt9RI4YsiC2p8Ul_Oa0STTG_wTnkQQujBZGrBPkMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/studio.vue?macro=true";
import { default as IndexpxWpcEedq_e2nNgD_45WGjRiUZ3u6h_45kENoE_38b4z9VAMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Index.vue?macro=true";
import { default as Editrc_YsoEXuOw9DuFyx3OWn_0zW20Bmgqq2l2QxoMLAuMMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Edit.vue?macro=true";
import { default as Create_E5T4mvYwxomGoCUU0cgMlPa69IuvDxCcZ1HXu2FLgQMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Create.vue?macro=true";
import { default as IndexKmYAk8Hci7TCx7oqze2oJ4d17wCDrCmraDekCe4BrdkMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Index.vue?macro=true";
import { default as CreatelaDfySlC8jd_v3kDoMFRiUBZDIGvyb_8Zo58bLtZQFMMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Create.vue?macro=true";
import { default as TranslatefwVBBach3L4ebRf9STdEq8mvh9rmPfIxkY9eYUgdD8QMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Translate.vue?macro=true";
import { default as Copy8gvVEQqAhszTbeMqLXurzx1bbgCWXDr7Du28bHpRABYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Copy.vue?macro=true";
import { default as IndexXjYlhPpoQDYAZp8NKNLBVRnWYUfjOYOSH2YZ_SjAGBoMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Index.vue?macro=true";
import { default as IndexnMQoayCWviv_o3Cxow5OvBoUU8hRwLuqQKmEnFSIwXEMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Index.vue?macro=true";
import { default as Edit5AiR7W8PzZeOKNCdGDLKFIRXyfegYpOTVyWPIEPiDKQMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Edit.vue?macro=true";
import { default as CreateYhSnQMuE3JbAVwNsjnUSlfhw3OU95X_vakJPWfwcZrcMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Create.vue?macro=true";
import { default as Index65K8V_NGfA8OSqaqsWFMrTXPmWbt_453YbMzZPILiMn2sMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Index.vue?macro=true";
import { default as CreateP3XVRKzbmof_45SdlJ5BrCXD4NPR1PS_fd55I9PZBlbyUMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Create.vue?macro=true";
import { default as Edit7buWfTKiIZ7wCHsJzj3Mnso4rPT6o3IQ3R5dcr3_g4wMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Edit.vue?macro=true";
import { default as ProfileIL576RecI2Iz_45pasGwNxZBkQavWtpZtGcOk2KD9VFu4Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Profile.vue?macro=true";
import { default as indexU5XNT_xXKfH8D7UfenR6ZJbAUn6rwkNRRdcPh62xD2cMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/index.vue?macro=true";
import { default as SlicesViewskiGpEjJoS97kAr3I1PV6pnrs89GZqsGQaZ9HjO7K_45oMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicesView.vue?macro=true";
import { default as TemplateViewOgR21_w43w1jKza4YM_XkJFZxUPVFRFPx1NvF1fpUzkMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/TemplateView.vue?macro=true";
import { default as DesignGuideViewAuQ74hzjBCk6SNn07BUhRfUKJ7XWH1y7QaPrrTtfcUsMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/DesignGuideView.vue?macro=true";
import { default as slicerULvJr9uRScpy4LRxn7OIzi7wgCTnOwCuZBxpKEq8D48Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/slicer.vue?macro=true";
import { default as documentAmt_45I7U2xDEBbgFplLDEanQ_2O_dhh8TRqqDkqiWoRYMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/documentation/document.vue?macro=true";
import { default as voixTNiia8CC3clxw7303xzQIEItF22KOAGNNJeQ6W9X01oMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix.vue?macro=true";
import { default as SlicePreviewD6CnH6ifRZwIKWYsLmH_FXS6rWJURTgYnQex7V8O2h4Meta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicePreview.vue?macro=true";
import { default as previewEVhvZhXT_45m9cIC9bf7VD3_N_45F4D_457PKb6mpyzWVHJIEMeta } from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/preview.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/home/bun/app/pages/[...slug].vue")
  },
  {
    name: "voix-mona",
    path: "/voix/mona",
    component: () => import("/home/bun/app/pages/voix/mona/index.vue")
  },
  {
    name: "voix-blogs",
    path: "/voix/blogs",
    component: () => import("/home/bun/app/pages/voix/blogs/index.vue")
  },
  {
    name: "voix-menus",
    path: "/voix/menus",
    component: () => import("/home/bun/app/pages/voix/menus/index.vue")
  },
  {
    name: "voix-blogs-create",
    path: "/voix/blogs/create",
    component: () => import("/home/bun/app/pages/voix/blogs/create.vue")
  },
  {
    name: "voix-caches",
    path: "/voix/caches",
    component: () => import("/home/bun/app/pages/voix/caches/index.vue")
  },
  {
    name: "voix-events",
    path: "/voix/events",
    component: () => import("/home/bun/app/pages/voix/events/index.vue")
  },
  {
    name: "voix-hotels",
    path: "/voix/hotels",
    component: () => import("/home/bun/app/pages/voix/hotels/index.vue")
  },
  {
    name: "voix-menus-create",
    path: "/voix/menus/create",
    component: () => import("/home/bun/app/pages/voix/menus/create.vue")
  },
  {
    name: "voix-events-create",
    path: "/voix/events/create",
    component: () => import("/home/bun/app/pages/voix/events/create.vue")
  },
  {
    name: "voix-cobrands",
    path: "/voix/cobrands",
    component: () => import("/home/bun/app/pages/voix/cobrands/index.vue")
  },
  {
    name: "voix-specials",
    path: "/voix/specials",
    component: () => import("/home/bun/app/pages/voix/specials/index.vue")
  },
  {
    name: "voix-blogs-edit-id",
    path: "/voix/blogs/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/blogs/edit/[id].vue")
  },
  {
    name: "voix-cobrands-create",
    path: "/voix/cobrands/create",
    component: () => import("/home/bun/app/pages/voix/cobrands/create.vue")
  },
  {
    name: "voix-menus-edit-id",
    path: "/voix/menus/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/menus/edit/[id].vue")
  },
  {
    name: "voix-mona-logs",
    path: "/voix/mona/logs",
    component: () => import("/home/bun/app/pages/voix/mona/logs/index.vue")
  },
  {
    name: "voix-specials-create",
    path: "/voix/specials/create",
    component: () => import("/home/bun/app/pages/voix/specials/create.vue")
  },
  {
    name: "voix-wait-list",
    path: "/voix/wait-list",
    component: () => import("/home/bun/app/pages/voix/wait-list/index.vue")
  },
  {
    name: "voix-error-logs",
    path: "/voix/error-logs",
    component: () => import("/home/bun/app/pages/voix/error-logs/index.vue")
  },
  {
    name: "voix-events-edit-id",
    path: "/voix/events/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/events/edit/[id].vue")
  },
  {
    name: "voix-hotels-edit-id",
    path: "/voix/hotels/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/hotels/edit/[id].vue")
  },
  {
    name: "voix-mona-pages",
    path: "/voix/mona/pages",
    component: () => import("/home/bun/app/pages/voix/mona/pages/index.vue")
  },
  {
    name: "voix-movie-times",
    path: "/voix/movie-times",
    component: () => import("/home/bun/app/pages/voix/movie-times/index.vue")
  },
  {
    name: "voix-restaurants",
    path: "/voix/restaurants",
    component: () => import("/home/bun/app/pages/voix/restaurants/index.vue")
  },
  {
    name: "voix-cobrands-edit-id",
    path: "/voix/cobrands/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/cobrands/edit/[id].vue")
  },
  {
    name: "voix-restaurants-create",
    path: "/voix/restaurants/create",
    component: () => import("/home/bun/app/pages/voix/restaurants/create.vue")
  },
  {
    name: "voix-specials-edit-id",
    path: "/voix/specials/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/specials/edit/[id].vue")
  },
  {
    name: "voix-travel-agents",
    path: "/voix/travel-agents",
    component: () => import("/home/bun/app/pages/voix/travel-agents/index.vue")
  },
  {
    name: "voix-press-releases",
    path: "/voix/press-releases",
    component: () => import("/home/bun/app/pages/voix/press-releases/index.vue")
  },
  {
    name: "voix-travel-agents-create",
    path: "/voix/travel-agents/create",
    component: () => import("/home/bun/app/pages/voix/travel-agents/create.vue")
  },
  {
    name: "voix-calendar-alerts",
    path: "/voix/calendar-alerts",
    component: () => import("/home/bun/app/pages/voix/calendar-alerts/index.vue")
  },
  {
    name: "voix-press-releases-create",
    path: "/voix/press-releases/create",
    component: () => import("/home/bun/app/pages/voix/press-releases/create.vue")
  },
  {
    name: "voix-restaurants-edit-id",
    path: "/voix/restaurants/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/restaurants/edit/[id].vue")
  },
  {
    name: "voix-travel-agents-edit-id",
    path: "/voix/travel-agents/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/travel-agents/edit/[id].vue")
  },
  {
    name: "voix-press-releases-edit-id",
    path: "/voix/press-releases/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/press-releases/edit/[id].vue")
  },
  {
    name: "voix-specials-categories",
    path: "/voix/specials-categories",
    component: () => import("/home/bun/app/pages/voix/specials-categories/index.vue")
  },
  {
    name: "voix-specials-categories-create",
    path: "/voix/specials-categories/create",
    component: () => import("/home/bun/app/pages/voix/specials-categories/create.vue")
  },
  {
    name: "voix-specials-categories-edit-id",
    path: "/voix/specials-categories/edit/:id()",
    component: () => import("/home/bun/app/pages/voix/specials-categories/edit/[id].vue")
  },
  {
    name: "voix-debug",
    path: "/voix-debug",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix-debug.vue")
  },
  {
    name: "voix-preview-release-entry-en",
    path: "/preview-release/:releaseId/:previewSecret",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Preview.vue")
  },
  {
    name: "voix",
    path: "/voix",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/voix.vue"),
    children: [
  {
    name: "voix-settings-en",
    path: "",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/settings-menu/Index.vue")
  },
  {
    name: "voix-login-en",
    path: "login",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/login.vue")
  },
  {
    name: "voix-auth-callback-en",
    path: "auth/callback",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/auth/callback.vue")
  },
  {
    name: "voix-studio-en",
    path: "studio",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/studio.vue")
  },
  {
    name: "voix-settings-head-tags-en",
    path: "head-tags",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Index.vue")
  },
  {
    name: "voix-settings-head-tags-edit-en",
    path: "head-tags/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Edit.vue")
  },
  {
    name: "voix-settings-head-tags-create-en",
    path: "head-tags/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/head-tags/Create.vue")
  },
  {
    name: "voix-settings-pages-en",
    path: "pages",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Index.vue")
  },
  {
    name: "voix-settings-pages-create-en",
    path: "pages/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Create.vue")
  },
  {
    name: "voix-settings-pages-translate-en",
    path: "pages/translate",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Translate.vue")
  },
  {
    name: "voix-settings-pages-copy-en",
    path: "pages/copy",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/pages/Copy.vue")
  },
  {
    name: "voix-settings-releases-en",
    path: "releases",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/releases/Index.vue")
  },
  {
    name: "voix-settings-redirects-en",
    path: "redirects",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Index.vue")
  },
  {
    name: "voix-settings-redirects-edit-en",
    path: "redirects/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Edit.vue")
  },
  {
    name: "voix-settings-redirects-create-en",
    path: "redirects/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/redirects/Create.vue")
  },
  {
    name: "voix-settings-users-en",
    path: "users",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Index.vue")
  },
  {
    name: "voix-settings-users-create-en",
    path: "users/create",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Create.vue")
  },
  {
    name: "voix-settings-users-edit-en",
    path: "users/:id",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Edit.vue")
  },
  {
    name: "voix-settings-users-profile-en",
    path: "profile",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/users/Profile.vue")
  },
  {
    name: "voix-cookbook-en",
    path: "cookbook",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/index.vue")
  },
  {
    name: "voix-slicer-en",
    path: "slicer",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/slicer.vue"),
    children: [
  {
    name: "voix-slicer-slices-en",
    path: "",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicesView.vue")
  },
  {
    name: "voix-slicer-template-en",
    path: "templates/:templateName",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/TemplateView.vue")
  },
  {
    name: "voix-slicer-design-guide-en",
    path: "design-guide",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/DesignGuideView.vue")
  }
]
  },
  {
    name: "voix-documentation-en",
    path: "documentation/:documentPath(.*)?",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/documentation/document.vue")
  }
]
  },
  {
    name: "slicer-slice-en",
    path: "/voix/slice-preview/:sliceName",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/components/slicer/views/SlicePreview.vue")
  },
  {
    name: "cookbook-preview-en",
    path: "/voix/cookbook-preview/:recipe(.*)",
    component: () => import("/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/pages/cookbook/preview.vue")
  }
]