import validate from "/home/bun/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import preview_45layout_45global from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/voix-layer/middleware/preview-layout.global.js";
import studio_45global from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/voix-layer/middleware/studio.global.js";
import unsaved_45draft_45alert_45global from "/home/bun/app/node_modules/@logicbomb-media/voix/dist/runtime/voix-layer/middleware/unsavedDraftAlert.global.js";
import reef_45pages_45gate_45global from "/home/bun/app/middleware/reef-pages-gate.global.ts";
import travel_45agents_45gate_45global from "/home/bun/app/middleware/travel-agents-gate.global.ts";
import manifest_45route_45rule from "/home/bun/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  preview_45layout_45global,
  studio_45global,
  unsaved_45draft_45alert_45global,
  reef_45pages_45gate_45global,
  travel_45agents_45gate_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}