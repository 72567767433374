
import * as unsplashRuntime$I9BihJZ5gmguBC2Sg0Ep3r2X_0eDVSpf3y9RyzR_45pZk from '/home/bun/app/node_modules/@nuxt/image/dist/runtime/providers/unsplash'
import * as cantoRuntime$JV5U_457d5iGBPG7akIo8jgTAocrDJDMmSakAFDxZhKto from '/home/bun/app/node_modules/@nuxt/image/dist/runtime/providers/imgix'
import * as awsRuntime$JV5U_457d5iGBPG7akIo8jgTAocrDJDMmSakAFDxZhKto from '/home/bun/app/node_modules/@nuxt/image/dist/runtime/providers/imgix'
import * as localRuntime$JV5U_457d5iGBPG7akIo8jgTAocrDJDMmSakAFDxZhKto from '/home/bun/app/node_modules/@nuxt/image/dist/runtime/providers/imgix'
import * as ipxRuntime$RO7SeQraT2A2rpr2lSmwgoCefkTqAxuulCmPkaaKfgQ from '/home/bun/app/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['unsplash']: { provider: unsplashRuntime$I9BihJZ5gmguBC2Sg0Ep3r2X_0eDVSpf3y9RyzR_45pZk, defaults: undefined },
  ['canto']: { provider: cantoRuntime$JV5U_457d5iGBPG7akIo8jgTAocrDJDMmSakAFDxZhKto, defaults: {"baseURL":"https://atlantisbahamas.imgix.net"} },
  ['aws']: { provider: awsRuntime$JV5U_457d5iGBPG7akIo8jgTAocrDJDMmSakAFDxZhKto, defaults: {"baseURL":"https://atlantisbahamas.imgix.net"} },
  ['local']: { provider: localRuntime$JV5U_457d5iGBPG7akIo8jgTAocrDJDMmSakAFDxZhKto, defaults: {"baseURL":"https://atlantisbahamas.imgix.net"} },
  ['ipx']: { provider: ipxRuntime$RO7SeQraT2A2rpr2lSmwgoCefkTqAxuulCmPkaaKfgQ, defaults: {} }
}
        