import { defineAsyncComponent } from 'vue'
export default {
  "atlantis-mobile-app": defineAsyncComponent(() => import("/home/bun/app/layouts/AtlantisMobileApp.vue").then(m => m.default || m)),
  atlantisbahamas: defineAsyncComponent(() => import("/home/bun/app/layouts/Atlantisbahamas.vue").then(m => m.default || m)),
  barbie: defineAsyncComponent(() => import("/home/bun/app/layouts/Barbie.vue").then(m => m.default || m)),
  "blue-project": defineAsyncComponent(() => import("/home/bun/app/layouts/BlueProject.vue").then(m => m.default || m)),
  empty: defineAsyncComponent(() => import("/home/bun/app/layouts/Empty.vue").then(m => m.default || m)),
  "music-making-waves": defineAsyncComponent(() => import("/home/bun/app/layouts/MusicMakingWaves.vue").then(m => m.default || m)),
  npiwff: defineAsyncComponent(() => import("/home/bun/app/layouts/Npiwff.vue").then(m => m.default || m)),
  paranza: defineAsyncComponent(() => import("/home/bun/app/layouts/Paranza.vue").then(m => m.default || m)),
  reef: defineAsyncComponent(() => import("/home/bun/app/layouts/Reef.vue").then(m => m.default || m)),
  summer: defineAsyncComponent(() => import("/home/bun/app/layouts/Summer.vue").then(m => m.default || m)),
  summer2024: defineAsyncComponent(() => import("/home/bun/app/layouts/Summer2024.vue").then(m => m.default || m)),
  "the-cove": defineAsyncComponent(() => import("/home/bun/app/layouts/TheCove.vue").then(m => m.default || m)),
  tide: defineAsyncComponent(() => import("/home/bun/app/layouts/Tide.vue").then(m => m.default || m))
}